import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
// https://www.geeksforgeeks.org/how-to-install-bootstrap-in-react-js/
// https://github.com/PriceRunner/react-ga4
import ReactGA from "react-ga4";

import './index.css';
// https://create-react-app.dev/docs/adding-a-stylesheet
import "./css/global.css";


// Multiple products (previously known as trackers)
ReactGA.initialize( [
    {
        trackingId: "G-SL6FHMGMC2"
    }
] );
const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
