import React from "react";
import { Link } from "react-router-dom";

import "../css/footer.css";

/**
 * @author Xiaoyu Tongyang, or call me sora for short
 * @see <a href="https://fengkeyleaf.com">person website</a>
 * @since 1.0
 */

// TODO: Always attached to the bottom of the browser, but not fixed.
function FooterComponent() {

    return (
        // Footer part starts
        <footer className="flex-center">
            <div id="footer-box" className="flex-space-between">
                {/*Left part*/}
                <div id="footer-logo-box">
                    {/* https://www.smashingmagazine.com/2019/03/css-alignment/ */}
                    <Link
                        to="/"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <img
                            src={ require( "../imgs/logo_icon_0_1.png" ) }
                            alt="fengkeyleaf-logo"
                            height="40px"
                        />
                        <img
                            src={ require( "../imgs/logo.png" ) }
                            alt="fengkeyleaf"
                            height="50px"
                            style={{ marginLeft: "10px" }}
                        />
                    </Link>
                </div>

                {/*Right part*/}
                <div id="footer-more">
                    <ul className="contact flex-end">
                        <li>
                            <a href="mailto:fengkeyleaf@gmail.com">
                                <img
                                    src={ require( "../imgs/email_icon.png" ) }
                                    alt="email" title="fengkeyleaf@gmail.com"
                                />
                            </a>
                        </li>
                        <li>
                            <img
                                src={ require( "../imgs/wechat_icon.png" ) }
                                alt="fengkeyleaf" title="fengkeyleaf"
                            />
                        </li>
                        <li>
                            <img
                                src={ require( "../imgs/discord_icon.png" ) }
                                alt="fengkeyleaf#3618" title="fengkeyleaf#3618"
                            />
                        </li>
                    </ul>

                    <p className="sub-text">
                        { "©" + new Date().getFullYear() + " fengkeyleaf. All rights reserved." }
                    </p>
                </div>
            </div>
        </footer>
        // Footer part ends
    )
}

export default FooterComponent