import axios from "axios";
import { ARTICLE_API_BASE_URL, ARTICLE_API_BASE_URL_AZURE } from "./ServerConfig";

/**
 * @author Xiaoyu Tongyang, or call me sora for short
 * @see <a href="https://fengkeyleaf.com">person website</a>
 * @since 1.0
 */

// TODO: Convert to function.
class CommentService {

    create( id, c ) {
        return axios.post( ARTICLE_API_BASE_URL_AZURE + "/" + id + "/comments", c );
    }

    getChain( aid, cid ) {
        return axios.get( ARTICLE_API_BASE_URL_AZURE + "/" + aid + "/comments?cid=" + cid );
    }

    mark( aid, mcid ) {
        return axios.get( ARTICLE_API_BASE_URL_AZURE + "/" + aid + "/comments/marked/" + mcid );
    }
}

export default new CommentService()