import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import { alertUnderConstruction } from "./Functions"

import "../css/blog.css";

/**
 * @author Xiaoyu Tongyang, or call me sora for short
 * @see <a href="https://fengkeyleaf.com">person website</a>
 * @since 1.0
 */

// TODO: Dark mode.
// TODO: Clickable buttons.
// TODO: Spacing between main sections( elements ).
// TODO: Sliding banner?
// https://www.w3schools.com/cssref/pr_class_cursor.php
function BlogComponent() {

    useEffect( () => {
        // Send pageview with a custom path
        ReactGA.send( { hitType: "pageview", page: window.location.pathname + window.location.search } );
    }, [] );

    // https://ui.dev/react-router-pass-props-to-link
    return (
        <div>
            <HeaderComponent />

            {/*https://www.w3schools.com/tags/tag_main.asp*/}
            <main>
                {/*Banner part*/}
                <div id="banner">
                    <Link
                        to="/blog/0/article/1"
                        id="banner-box"
                        className="display-block"
                    >
                        {/* First display layer, z-index: 2 */}
                        <div id="banner-title" style={{ zIndex: "2" }}>
                            <h1 className="main-text comment-inner-gap text-align-start">
                                Voronoi图（一）：塞尔达背后的计算几何
                            </h1>
                            <p className="main-text comment-inner-gap text-align-start">
                                玩过塞尔达传说：荒野之息的朋友，应该都会赞叹海拉鲁风景的美丽。我们的林克时不时一人独自在战后废墟中探索一座座神庙
                            </p>
                            <button className="comment-inner-gap clickable">Read Article</button>
                        </div>
                        {/* Second display layer, z-index: 1 */}
                        <div
                            className="bg-gradient"
                            // Use element style to override CSS styles in article.css
                            // Direction - Top to Bottom (this is default)
                            // https://stackoverflow.com/questions/52950627/inline-style-in-react-background-linear-gradient
                            style={{
                                backgroundImage: "linear-gradient( rgba( 255, 255, 255, 0 ), rgba( 255, 255, 255, 0.6 ) )",
                                position: "absolute", top: "100px", zIndex: "1"
                            }}
                        ></div>
                        {/* Last display layer, default z-index */}
                        <div id="banner-img"></div>
                    </Link>
                </div>

                {/*Main content part starts*/}
                <div id="main-content">
                    {/*Main content box starts*/}
                    <div id="main-content-box" className="flex-space-between main-content-width">
                        {/*Main content left part. Main selected*/}
                        <div className="flex-center">
                            {/*From left to right*/}
                            {/*Main selected 1*/}
                            <Link
                                to="/blog/0/article/5"
                                className="main-selected-card section-inner-gap-right display-block"
                            >
                                <img src={require("../imgs/cat.jpg")} className="comment-inner-gap"/>
                                <h3 className="main-text comment-inner-gap">清华计算几何大作业（三）：CG2017 PA1-2 Crossroad (十字路口)</h3>
                                <p className="sub-text comment-inner-gap">在解析处理直线、射线和圆的处理方式之前</p>
                                <button className="sub-button clickable">Read Article</button>
                            </Link>

                            {/*Main selected 2*/}
                            <Link
                                to="/blog/0/article/10"
                                className="main-selected-card section-inner-gap-right display-block"
                            >
                                <img src={require("../imgs/cat.jpg")} className="comment-inner-gap"/>
                                <h3 className="main-text comment-inner-gap">清华计算几何大作业（八）：CG2017 PA2-2 Find Dancing Partners (寻找舞伴)</h3>
                                <p className="sub-text comment-inner-gap">Voronoi Diagrams 构造实现讲解系列 · 上半部</p>
                                <button className="sub-button clickable">Read Article</button>
                            </Link>
                        </div>

                        {/*Main content right part. Sub selected*/}
                        <div id="main-content-box-right">
                            {/*From top to bottom*/}
                            {/*Sub selected 1*/}
                            <Link
                                to="/blog/0/article/16"
                                className="sub-selected-card section-inner-gap-bottom display-block"
                            >
                                <h3 className="main-text comment-inner-gap">Voronoi图（二）：基本概念和性质</h3>
                                <p className="sub-text">这里我们着重介绍和实现关联比较大的概念和性质</p>
                            </Link>

                            {/*Sub selected 2*/}
                            <Link
                                to="/blog/0/article/17"
                                className="sub-selected-card section-inner-gap-bottom display-block"
                            >
                                <h3 className="main-text comment-inner-gap">Voronoi图（三）：构造Voronoi图</h3>
                                <p className="sub-text">对于Voronoi的构造，我们究竟使用什么策略比较合适呢？</p>
                            </Link>

                            {/*Sub selected 3*/}
                            <Link
                                to="/blog/0/article/19"
                                className="sub-selected-card section-inner-gap-bottom display-block"
                            >
                                <h3 className="main-text comment-inner-gap">Voronoi图（四）：抛物线的妙用</h3>
                                <p className="sub-text">在上一节，我们已经了解了Voronoi图大致的构造思路</p>
                            </Link>

                            {/*Sub selected 4*/}
                            <Link
                                to="/blog/0/article/33"
                                className="sub-selected-card section-inner-gap-bottom display-block"
                            >
                                <h3 className="main-text comment-inner-gap">超详细 | 红黑树详解文章汇总（含代码）</h3>
                                <p className="sub-text">一次性掌握红黑树</p>
                            </Link>
                        </div>

                    </div>
                    {/*Main content box ends*/}
                </div>
                {/*Main content part ends*/}

                {/* TODO: map() to generate category */}
                {/*category part starts*/}
                <div id="category" className="flex-center">
                    {/*category box 1*/}
                    <div className="cate-box main-content-width">
                        <Link
                            to="#"
                            className="cate-up-part section-inner-gap-bottom"
                            state={{ aid: 1 }}
                            onClick={ alertUnderConstruction }
                        >
                            <h3 className="main-text">计算几何 | Computational Geometry</h3>
                            <button className="sub-button clickable">See All Articles</button>
                        </Link>

                        <div className="cate-down-part">
                            <Link
                                to="/blog/0/article/18"
                                className="cate-card display-block"
                            >
                                <img src={ require("../imgs/cat.jpg") } className="comment-inner-gap"/>
                                <h3 className="main-text comment-inner-gap">清华计算几何大作业思路分析和代码实现</h3>
                                <p className="sub-text">距离第一次接触计算几何到现在，不知不觉已经过去了一整年的时间，从一开始什么都不会，被DCEL折磨的死去活来，到现在基本还能玩转DCEL</p>
                            </Link>

                            <Link
                                to="/blog/0/article/4"
                                className="cate-card display-block"
                            >
                                <img src={ require("../imgs/cat.jpg") } className="comment-inner-gap"/>
                                <h3 className="main-text comment-inner-gap">清华计算几何大作业（二）：CG2017 PA5-1 Dynamic Convex Hull（动态凸包）</h3>
                                <p className="sub-text">并且提示“第一类操作是计算时间开销的主要部分”和“可能需要实现合适的平衡二叉树”</p>
                            </Link>

                            <Link
                                to="/blog/0/article/3"
                                className="cate-card display-block"
                            >
                                <img src={ require("../imgs/cat.jpg") } className="comment-inner-gap"/>
                                <h3 className="main-text comment-inner-gap">清华计算几何大作业（一）：CG2017 PA1-1 Convex Hull (凸包)</h3>
                                <p className="sub-text">这题可以说是10个大作业当中最简单的一个，直接按照课程中分析的方法实现即可</p>
                            </Link>

                            <Link
                                to="/blog/0/article/30"
                                className="cate-card display-block"
                            >
                                <img src={ require("../imgs/cat.jpg") } className="comment-inner-gap"/>
                                <h3 className="main-text comment-inner-gap">几何寻路：漏斗算法（Funnel Algorithm）</h3>
                                <p className="sub-text">如果玩过求生之路的童鞋可能会好奇，成群的尸潮是如何从出生点快速来到玩家的周围呢？</p>
                            </Link>
                        </div>
                    </div>

                </div>
                {/*category part ends*/}
            </main>

            <FooterComponent />
        </div>
    )
}

export default BlogComponent