import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import ReactMarkdown from "react-markdown";

import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";

import "../css/article.css";
import "github-markdown-css/github-markdown-light.css";

/**
 * @author Xiaoyu Tongyang, or call me sora for short
 * @see <a href="https://fengkeyleaf.com">person website</a>
 * @since 1.0
 */

function AboutComponent() {
    const SUB_TITLE = "Which frameworks we're using and who're working on this project";
    const CONTENT = "## 1. Technique Stack\n" +
        "\n" +
        "### 1.1 Front-end\n" +
        "\n" +
        "- ❄ React\n" +
        "- ❄ Axios\n" +
        "\n" +
        "### 1.2 Back-end\n" +
        "\n" +
        "- ❄ Spring Boot\n" +
        "- ❄ Hibernate\n" +
        "- ❄ REST APIs with Spring MVC\n" +
        "\n" +
        "### 1.3 Database\n" +
        "\n" +
        "- ❄ MySQL\n" +
        "\n" +
        "### 1.4 Cloud Service\n" +
        "\n" +
        "- ❄ Mircosoft Azure\n" +
        "\n" +
        "## 2. Staff\n" +
        "\n" +
        "- ❄ Prototyping, UI/UX design, web front-end & back-end development: @[fengkeyleaf](mailto:fengkeyleaf@gmail.com)\n" +
        "- ❄ Web development consultor: @[益达](https://mskf.work)\n" +
        "- ❄ Logo icon design: @[田雨奇](https://www.behance.net/yuqitian)\n" +
        "\n" +
        "## 3. UI Design References\n" +
        "\n" +
        "- ❄ [SmartUV Blog](https://dribbble.com/shots/15491024-SmartUV-Blog/attachments/7266253?mode=media)\n" +
        "- ❄ [Minimal blog post — Untitled UI](https://dribbble.com/shots/16848454-Minimal-blog-post-Untitled-UI)";

    useEffect( () => {
        // Send pageview with a custom path
        ReactGA.send( { hitType: "pageview", page: window.location.pathname + window.location.search } );
        // https://github.com/nfl/react-helmet/issues/623
        document.title = "About Page";
    }, [] );

    return (
        <div>
            <HeaderComponent/>

            {/* Same structure as ArticleComponent */}
            <main>
                <div className="bg-gradient"></div>

                {/*Article title part starts*/ }
                <div id="title" className="flex-center">
                    <div>
                        <h1 className="main-text">About</h1>
                        <p className="sub-text">{ SUB_TITLE }</p>
                    </div>
                </div>
                {/*Article title part ends*/ }

                <div className="flex-center section-gap-top">
                    <div className="markdown-view markdown-body">
                        <ReactMarkdown children={ CONTENT } />
                    </div>
                </div>
            </main>

            <FooterComponent/>
        </div>
    )
}

export default AboutComponent