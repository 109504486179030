// https://stackoverflow.com/a/43470639
// https://reactrouter.com/en/main/router-components/hash-router
import {
    createBrowserRouter,
    RouterProvider,
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import ArticleComponent from "./components/ArticleComponent";
// https://stackoverflow.com/questions/69843615/switch-is-not-exported-from-react-router-dom
import BlogComponent from "./components/BlogComponent";
import AboutComponent from "./components/AboutComponent";
import ArticleService from "./services/ArticleService";

/**
 * @author Xiaoyu Tongyang, or call me sora for short
 * @see <a href="https://fengkeyleaf.com">person website</a>
 * @since 1.0
 */

// TODO: Video format in article.
// TODO: Markdown: title tag, ##, should be # in articles.
// TODO: image in the center by default.
// TODO: favicon.icon., squad shape.
// TODO: Link is invalid when pressing mouse scrolling key.
function App() {

    // https://reactrouter.com/en/main/routers/create-browser-router
    // https://reactrouter.com/en/main/route/loader
    // https://reactrouter.com/en/main/hooks/use-loader-data
    const router = createBrowserRouter([
        // https://medium.com/@sampath.katari/use-of-exact-prop-for-route-in-react-router-v4-fdbe20e8925d
        // https://stackoverflow.com/questions/69854011/matched-leaf-route-at-location-does-not-have-an-element
        {
            path: "/",
            element: <BlogComponent />
        },
        {
            path: "/blog",
            element: <BlogComponent />
        },
        {
            // https://reacttraining.com/blog/react-router-v5-1
            path: "/blog/:uid/article/:aid",
            element: <ArticleComponent />,
            loader: async ( { params } ) => {
                let d = null;
                // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/async_function
                // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/await
                // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/then
                await ArticleService.getArticle( params.aid ).then( r => {
                    d = r.data;
                } );
                // TODO: catch componentDidMount

                return d;
            }
        },
        {
            path: "/blog/about",
            element: <AboutComponent />
        }
    ]);

    return (
        <div>
            {/* https://reactrouter.com/en/main/routers/create-browser-router */}
            <RouterProvider router={ router } />
        </div>
    );
}

export default App;
