import React, { useEffect, useState } from "react";
// Markdown components
// https://github.com/remarkjs/react-markdown
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useLoaderData, useParams } from "react-router-dom";
// https://github.com/remarkjs/remark-math/tree/main/packages/remark-math
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import ReactGA from "react-ga4";

import ArticleService from "../services/ArticleService";
import ReplyFormComponent from "./ReplyFormComponent";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import CommentService from "../services/CommentService";

import "../css/article.css";
// https://github.com/sindresorhus/github-markdown-css
import "github-markdown-css/github-markdown-light.css";
// https://github.com/remarkjs/remark-math/tree/main/packages/rehype-katex
import "katex/dist/katex.min.css";

/**
 * @author Xiaoyu Tongyang, or call me sora for short
 * @see <a href="https://fengkeyleaf.com">person website</a>
 * @since 1.0
 */

// TODO: No comment line when no cooments.
// TODO: go-back button
// TODO: Random Genshin impact avatars.
// TODO: Quick reply when too many comments or very long article.
// TODO: Limit the width of the title and description, ... when they're too long.
// TODO: Synchronize article content, sometimes only website framework will be loaded first.
// https://www.geeksforgeeks.org/how-to-create-a-multi-page-website-using-react-js/
function ArticleComponent() {

    const REPLY_FOCUS = "reply-focus";
    const COMMENT_FOCUS = "comment-focus";
    // https://reactrouter.com/en/main/hooks/use-loader-data#useloaderdata
    const [ page, setPage ] = useState( useLoaderData() );
    const [ replyId, setReplyId ] = useState( -1 );
    // useLocation()
    // https://reactrouter.com/en/6.6.2/hooks/use-location
    // https://ui.dev/react-router-pass-props-to-link
    // https://github.com/remix-run/react-router/issues/5883
    // useParams()
    // https://reacttraining.com/blog/react-router-v5-1
    // https://stackoverflow.com/a/58407515/15968350
    // TODO: Get rid of aid.
    const { aid } = useParams();

    /**
     * Get the initial data ( related to an article and its comments )
     * after mounting the component.
     * */

    // https://reactjs.org/docs/hooks-effect.html
    // https://shipshape.io/blog/wait-for-page-load-in-react/
    // https://beta.reactjs.org/reference/react/useEffect#reference
    useEffect( () => {
        // Send pageview with a custom path
        ReactGA.send( { hitType: "pageview", page: window.location.pathname + window.location.search } );

        console.assert( page.a.title )
        document.title = page.a.title;
    }, [] );

    const updatePage = () => {
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/then
        ArticleService.getArticle( aid ).then( r => {
            console.log( r );
            setPage( r.data );
        } );
    }

    // https://jsdoc.app/tags-param.html

    /**
     * @param {SyntheticEvent} e - event
     * @param {String} aid - Article aid.
     * @param {String} cid - Comment aid to track comment chain.
     * */

    const getCommentChain = ( e, aid, cid ) => {
        // console.log( e );
        // https://salesforce.stackexchange.com/questions/374304/how-to-make-the-anchor-tag-clickable-without-any-redirector-without-any-refresh
        e.preventDefault();

        // https://reactjs.org/docs/hooks-effect.html
        // https://reactjs.org/docs/react-component.html#componentdidmount
        CommentService.getChain( aid, cid ).then( r => {
            console.log( r );
            setPage( r.data );
        } ).then( () => {
            document.getElementById( COMMENT_FOCUS ).scrollIntoView( { behavior: "smooth" } );
        } );

        // TODO: catch getCommentChain, handle errors.
    }

    /**
     * @param {SyntheticEvent} e - event
     * @param {String} aid - Article aid.
     * @param {String} mcid - Comment aid to be marked.
     * */

    const markComment = ( e, aid, mcid ) => {
        e.preventDefault();

        CommentService.mark( aid, mcid ).then( r => {
            console.log( r );
            setPage( r.data );
        } ).then( () => {
            // TODO: Go to the first marked commnet or focus where? recall no "go back: button fo rnow.
            document.getElementById( COMMENT_FOCUS ).scrollIntoView( { behavior: "smooth" } );
        } );
        // TODO: catch markComment, handle errors.
        // TODO: Set article content's text color to main-text-color.
    }

    // https://react-guide.github.io/react-router-cn/docs/Introduction.html
    return (
        <div>
            <HeaderComponent/>

            <main>
                <div className="bg-gradient"></div>

                {/*Article title part starts*/ }
                <div id="title" className="flex-center">
                    <div>
                        <h1 className="main-text">{ page ? page.a.title : "" }</h1>
                        <p className="sub-text">{ page ? page.a.description : "" }</p>
                    </div>
                </div>
                {/*Article title part ends*/ }

                {/* TODO: Add share options */}
                {/*Article sharing part starts*/ }
                <div style={{ display: "none" }} className="flex-center contact">
                    <div>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                    </div>
                </div>
                {/*Article sharing part ends*/ }

                {/*Article content starts*/ }
                <div className="flex-center section-gap-top">
                    <div id="content-view" className="markdown-view markdown-body">
                        <ReactMarkdown
                            children={ page ? page.a.content : null }
                            // https://katex.org/docs/issues.html
                            // https://katex.org/docs/options.html
                            remarkPlugins={ [ [ remarkGfm ], [ remarkMath ] ] }
                            rehypePlugins={ [ rehypeKatex ] }
                            components={ {
                                code( { node, inline, className, children, ...props } ) {
                                    const match = /language-(\w+)/.exec( className || '' )
                                    return !inline && match ? (
                                        <SyntaxHighlighter
                                            children={ String( children ).replace( /\n$/, '' ) }
                                            language={ match[ 1 ] }
                                            PreTag="div"
                                            { ...props }
                                        />
                                    ) : (
                                        <code className={ className } { ...props }>
                                            { children }
                                        </code>
                                    )
                                }
                            } }
                        />
                    </div>
                </div>
                {/*Article content ends*/ }

                {/*Comments starts*/ }
                <div className="section-gap-top">
                    {/*Separate Line*/ }
                    <div id={ COMMENT_FOCUS } className="flex-center section-inner-gap-bottom">
                        <div className="separate-line markdown-view"></div>
                    </div>

                    {/* Comment area starts */ }
                    {
                        page ? page.C.map(
                            // first -> c, second -> { p: parent }
                            c =>
                                <div className="comment-gap">
                                    {/* Upper part, profile */ }
                                    <div className="flex-center comment-inner-gap">
                                        <div className="markdown-view flex-space-between">
                                            <div className="profile-left flex-center">
                                                <img className="avatar" src={ require( "../imgs/avator.jpg" ) }/>
                                                <h4>{ c.cu.name }</h4>
                                                {/*https://www.w3schools.com/react/react_conditional_rendering.asp*/ }
                                                {/*https://beta.reactjs.org/learn/conditional-rendering*/ }
                                                <img
                                                    className="reply-icon"
                                                    style={ { display: c.p ? "flex" : "none" } }
                                                    src={ require( "../imgs/right_triangle_icon.png" ) }
                                                />
                                                <h4
                                                    style={ { display: c.p ? "flex" : "none" } }
                                                >{ c.pu ? c.pu.name : "" }</h4>
                                            </div>

                                            <div className="profile-right">
                                                {/*Comment chain icons*/ }
                                                {/* https://stackoverflow.com/questions/13548225/what-is-htmls-a-a-tag-default-display-type */ }
                                                {/* https://javascript.plainenglish.io/react-router-and-spas-made-easy-19fd1db0d6fc */}
                                                {/* https://www.taniarascia.com/using-react-router-spa/ */}
                                                {/* https://www.geeksforgeeks.org/link-and-navlink-components-in-react-router-dom/ */}
                                                <a
                                                    onClick={ ( e ) => getCommentChain( e, page.a.id, c.c.id ) }
                                                    style={ { display: page.F[ 0 ] ? "inline" : "none" } }
                                                    href="#"
                                                >
                                                    <img
                                                        src={ c.c.marked ? require( "../imgs/chain_red_icon.png" ) : require( "../imgs/chain_icon.png" ) }
                                                    />
                                                </a>
                                                <a
                                                    onClick={ ( e ) => markComment( e, page.a.id, c.c.id ) }
                                                    style={ { display: page.F[ 1 ] ? "inline" : "none" } }
                                                    href="#"
                                                >
                                                    <img src={ require( "../imgs/marker.png" ) }/>
                                                </a>

                                                {/* More options icons */ }
                                                <a
                                                    onClick={ ( e ) => {
                                                        e.preventDefault();

                                                        document.getElementById( REPLY_FOCUS ).scrollIntoView( { behavior: "smooth" } );
                                                        setReplyId( c.c.id );
                                                    } }
                                                    href="#">
                                                    <img src={ require( "../imgs/reply_icon.png" ) }/>
                                                </a>
                                            </div>

                                        </div>
                                    </div>

                                    {/* Lower part, markdown text area */ }
                                    <div className="flex-center">
                                        <div id={ "comment-view" + c.c.id } className="markdown-view">
                                            <ReactMarkdown
                                                children={ c.c.content }
                                                remarkPlugins={ [ [ remarkGfm ], [ remarkMath ] ] }
                                                rehypePlugins={ [ rehypeKatex ] }
                                                components={ {
                                                    code( { node, inline, className, children, ...props } ) {
                                                        const match = /language-(\w+)/.exec( className || '' )
                                                        return !inline && match ? (
                                                            <SyntaxHighlighter
                                                                children={ String( children ).replace( /\n$/, '' ) }
                                                                language={ match[ 1 ] }
                                                                PreTag="div"
                                                                { ...props }
                                                            />
                                                        ) : (
                                                            <code className={ className } { ...props }>
                                                                { children }
                                                            </code>
                                                        )
                                                    }
                                                } }
                                            />
                                        </div>
                                    </div>
                                </div>
                        ) : null
                    }
                    {/* Comment area ends */ }
                </div>
                {/* Comments ends */ }

                {/* Reply Area */}
                <ReplyFormComponent
                    updatePage={ updatePage }
                    replyId={ replyId }
                    setReplyId={ setReplyId }
                />
                <div id={ REPLY_FOCUS }></div>
            </main>

            <FooterComponent/>
        </div>
    )
}

export default ArticleComponent