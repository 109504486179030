import React from "react";
import { Link } from "react-router-dom";
import { alertUnderConstruction } from "./Functions";

import "../css/header.css";

/**
 * @author Xiaoyu Tongyang, or call me sora for short
 * @see <a href="https://fengkeyleaf.com">person website</a>
 * @since 1.0
 */

// TODO: User navbar tage
// https://getbootstrap.com/docs/5.3/components/navbar/
// https://bobbyhadz.com/blog/react-where-to-put-images
function HeaderComponent() {

    // TODO: logo link to: fengkeyleaf's blog and logo's alt attribute.
    // https://stackoverflow.com/a/49785719
    return (
        // Header part starts
        <header>
            <div id="header-box">
                {/*Left part*/ }
                <div id="header-box-left">
                    <ul id="header-logo-box" className="flex-center">
                        <li>
                            <Link to="/" className="display-block" style={{ position: "relative" }}>
                                <img
                                    src={ require( "../imgs/logo-main-text-color.png" ) }
                                    alt="fengkeyleaf"
                                    height="50px"
                                    style={{ opacity: 0.85 }}
                                />
                                <img
                                    src={ require( "../imgs/logo_icon_0_1.png" ) }
                                    alt="fengkeyleaf-logo"
                                    style={{ width: "25px", position: "absolute", left: "150px" }}
                                />
                            </Link>
                        </li>
                        <li id="about">
                            <Link to="/blog/about" className="main-text">About</Link>
                        </li>
                    </ul>
                </div>

                {/*Right part*/ }
                <div id="header-box-right">
                    <a id="search-box" href="#" onClick={ alertUnderConstruction }>
                        <img id="search-icon" src={ require( "../imgs/search_icon.png" ) }/>
                    </a>
                    <a id="language-box" href="#" onClick={ alertUnderConstruction }>
                        <img id="language-icon" src={ require( "../imgs/language_icon.png" ) }/>
                        <img id="language-more" src={ require( "../imgs/down_triangle_icon.png" ) }/>
                    </a>
                </div>
            </div>
        </header>
        // Header part ends
    )
}

export default HeaderComponent