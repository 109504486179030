import React from 'react';
// https://react-hook-form.com/get-started/
import { useForm } from 'react-hook-form';
// https://uiwjs.github.io/react-md-editor/
import MDEditor, { commands } from "@uiw/react-md-editor";
import CommentService from "../services/CommentService";

import "../css/replyFrom.css"

const mkdStr = "1) Email is required and will be recorded for future registration.\n2) Image upload is disabled for now.";

/**
 * @author Xiaoyu Tongyang, or call me sora for short
 * @see <a href="https://fengkeyleaf.com">person website</a>
 * @since 1.0
 */

// https://mailtrap.io/blog/validate-emails-in-react/
// https://reactjs.org/docs/components-and-props.html
// https://reactjs.org/docs/jsx-in-depth.html#children-in-jsx
export default function ReplyFormComponent( props ) {

    const [ value, setMarkdownValue ] = React.useState( mkdStr );
    const [ server, setServer ] = React.useState( false );

    const {
        register,
        handleSubmit,
        formState,
        formState: { errors, isSubmitSuccessful },
        setValue,
        reset
    } = useForm( {
       defaultValues: {
           id: 1, // article id
           reply: mkdStr
       }
    } );

    /**
     * Submit the comment ( in the form ) to the server,
     * refresh the comment area when successfully added,
     * otherwise display server error message.
     * */

    // https://reactjs.org/docs/lifting-state-up.html
    const onSubmit = ( data ) => {
        // https://www.w3schools.com/js/js_errors.asp
        // https://axios-http.com/docs/handling_errors
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise

        // Post request to save the comment in the server.
        CommentService.create( data.id, {
            content: value,
            username: data.username,
            userEmail: data.userEmail,
            parentId: props.replyId
        } ).then( r => {
            // TODO optimize: update article page after adding a comment without querying the database.
            // Successfully created the comment, re-load comments.
            props.updatePage();
        } ).catch( e => {
            console.log( e );
            // Errors occurred in the server, display the error messge.
            setServer( true )
        } );

        // TODO: Test this.
        // Reset replyId
        props.setReplyId( -1 );
    }

    // https://react-hook-form.com/api/useform/reset
    // https://retool.com/blog/hooks-and-state-102-the-dependency-array-in-useeffect/
    React.useEffect(() => {
        if ( isSubmitSuccessful ) {
            reset( {
                username: "",
                userEmail: "",
                id: 1, // article id
                reply: mkdStr
            } );

            setMarkdownValue( mkdStr );
        }
    }, [ formState, reset ] );

    /**
     * Pass the content in the markdown area to the markdown component,
     * as well as to an input tag associated to the form,
     * allowing react-hook-form to validate the content.
     * */

    // https://reactjs.org/docs/forwarding-refs.html#forwarding-refs-to-dom-components
    const markdownOnChange = ( v ) => {
        // console.log( v );
        setMarkdownValue( v );
        setValue( "reply", v );
        // console.log( getValues( "reply" ).length );
        // console.log( errors.reply );
    }

    return (
        <div className="flex-center section-gap-top">
            <div>
                {/* Separate Line */ }
                <div className="separate-line markdown-view"></div>

                {/* https://www.w3schools.com/tags/att_form_action.asp */}
                <form
                    role="form"
                    onSubmit={ handleSubmit( onSubmit ) }
                >
                    <div className="flex-space-evenly section-inner-gap-top">
                        {/* https://www.w3schools.com/tags/tag_input.asp */ }
                        <div>
                            <input
                                placeholder="Username (Optional)"
                                type="text"
                                className="formInput"
                                // https://stackoverflow.com/questions/12018245/regular-expression-to-validate-username
                                { ...register( "username", {
                                    pattern: /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/i
                                } ) }
                            />
                            { errors.username &&
                                <div className="danger-text">
                                    Invalid username
                                </div> }
                        </div>

                        <div>
                            <input
                                placeholder="Useremail (Required)"
                                type="text"
                                className="formInput"
                                { ...register( "userEmail", {
                                    required: true,
                                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    maxLength: 320
                                } ) }
                            />
                            { errors.userEmail &&
                                <div className="danger-text">
                                    Invalid email
                                </div> }
                        </div>

                        <input
                            type="text"
                            style={{ display: "none" }}
                            { ...register( "id" ) }
                        />
                    </div>

                    <div className="markdown-view section-inner-gap-top">
                        <MDEditor
                            height={ 400 }
                            value={ value }
                            // https://uiwjs.github.io/react-md-editor/#custom-toolbars
                            commands={ commands.getCommands().filter( t => t.name !== "image") }
                            onChange={ markdownOnChange }
                        />
                        <input
                            type="text"
                            // defaultValue={ value }
                            style={{ display: "none" }}
                            { ...register( "reply", {
                                // required: true,
                                // pattern: /^.+$/i,
                                minLength: 1,
                                maxLength: 1000
                            } ) }
                        />
                        {/* TODO: Simplify those two error message */}
                        { errors.reply &&
                            <div className="danger-text">
                                Empty or too many content
                            </div> }
                        { server &&
                            <div className="danger-text">
                                Something went wrong! Please try again
                            </div> }
                    </div>

                    <div className="flex-center section-inner-gap-top">
                        <input className="sub-button clickable" id="submit" type="submit" value="Add a comment" />
                    </div>
                </form>

            </div>
        </div>
    );
}